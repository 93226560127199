<template>
  <div>
    <p>Aby dostarczyć Państwa towary korzystamy z usług tej firmy:</p>
    <p class="bold">
      UPS Standard, UPS Express (dostawa następnego dnia) - zamówienia złożone
      online do godziny 14:00 są przetwarzane tego samego dnia.
    </p>
    <p>Podana liczba dni nie obejmuje dnia wysyłki.</p>
    <figure class="figure">
      <img :src="ups" alt="UPS" />
      <figcaption>*Źródło: www.ups.com</figcaption>
    </figure>
    <p class="bold">UPS</p>
    <table class="table table-striped table-bordered">
      <tbody>
        <tr>
          <th>Kraj:</th>
          <th>Szacunkowy czas realizacji zamówienia (dni robocze)</th>
        </tr>
        <tr>
          <td>Niemcy, Czechy, Słowacja</td>
          <td>2 dni</td>
        </tr>
        <tr>
          <td>Francja, Belgia, Dania, Holandia, Austria, Szwajcaria</td>
          <td>3 dni</td>
        </tr>
        <tr>
          <td>
            Wielka Brytania, Hiszpania, Włochy, Słowenia, Węgry, Rumunia
          </td>
          <td>4 dni</td>
        </tr>
        <tr>
          <td>
            Portugalia, Grecja, Chorwacja, Litwa, Łotwa, Estonia, Norwegia
          </td>
          <td>5 dni</td>
        </tr>
      </tbody>
    </table>
    <p class="bold">DPD</p>
    <table class="table table-striped table-bordered">
      <tbody>
        <tr>
          <th>Kraj:</th>
          <th>Szacunkowy czas realizacji zamówienia (dni robocze)</th>
        </tr>
        <tr>
          <td>Belgia</td>
          <td>3 dni</td>
        </tr>
        <tr>
          <td>Bułgaria</td>
          <td>5 dni</td>
        </tr>
        <tr>
          <td>Czechy</td>
          <td>3 dni</td>
        </tr>
        <tr>
          <td>Finlandia</td>
          <td>5 dni</td>
        </tr>
        <tr>
          <td>Francja</td>
          <td>4 dni</td>
        </tr>
        <tr>
          <td>Niemcy</td>
          <td>3 dni</td>
        </tr>
        <tr>
          <td>Grecja</td>
          <td>6 dni</td>
        </tr>
        <tr>
          <td>Włochy</td>
          <td>5 dni</td>
        </tr>
        <tr>
          <td>Holandia</td>
          <td>3 dni</td>
        </tr>
        <tr>
          <td>Polska</td>
          <td>1 dzień</td>
        </tr>
        <tr>
          <td>Portugalia</td>
          <td>5 dni</td>
        </tr>
        <tr>
          <td>Rumunia</td>
          <td>4 dni</td>
        </tr>
        <tr>
          <td>Hiszpania</td>
          <td>5 dni</td>
        </tr>
        <tr>
          <td>Szwecja</td>
          <td>4 dni</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ups from "@/assets/UPS.png";

export default {
  data() {
    return {
      ups
    };
  }
};
</script>
