<template>
  <v-app>
    <nav class="white">
      <nav-desktop class="hidden-sm-and-down" />
      <nav-mobile class="hidden-md-and-up" />
    </nav>
    <marquee-component />
    <v-main class="white">
      <router-view :key="currency || 'eur'" />
    </v-main>
    <cookies />
    <footer-section />
  </v-app>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import axios from "axios";
import NavDesktop from "./components/navigation/desktop/Desktop";
import NavMobile from "./components/navigation/mobile/Mobile";
import FooterSection from "./components/Footer";
import Cookies from "@/components/Cookies";
import MarqueeComponent from "./components/home/Marquee.vue";
import cfg from "./3c.config";
import { checkUserCountry } from "ozparts-client";

export default {
  name: "App",

  components: {
    Cookies,
    NavDesktop,
    NavMobile,
    FooterSection,
    MarqueeComponent
  },

  computed: {
    ...mapState(["currency"])
  },

  async created() {
    try {
      const country = await checkUserCountry();
      const data = await axios
        .get(`https://ozparts2.usermd.net/api/external/source/${cfg.SOURCE}`)
        .then(res => res.data);
      this.setUserCountry(country);
      this.setUserRegionData(data);
    } catch (e) {
      this.setUserCountry("PL");
    }
  },

  methods: {
    ...mapMutations(["setUserCountry", "setUserRegionData"])
  }
};
</script>

<style lang="scss">
body {
  --red: #ed3730;
  --primary: #ff5d03;
  --secondary: #2f2f2f;

  line-height: 1.5;
  white-space: normal;
  font-family: "Montserrat", sans-serif;
}
.nav-button {
  font-family: "Michroma", sans-serif;
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  padding: 0.5em;
  margin: 0 0.4em;
  transition-duration: 0.5s;

  &:hover {
    background-color: var(--primary);
    color: white;
    cursor: pointer;
    transition-duration: 0.2s;
  }
}

.nav-active {
  background-color: var(--primary);
  color: white;
}

.container {
  padding-top: 12px;
  padding-bottom: 12px;
}
</style>
