<template>
  <b-container>
    <v-progress-circular
      v-if="showLoader"
      indeterminate
      class="loader"
    ></v-progress-circular>
    <div v-else>
      <div v-if="!items.length" class="no-result">
        <h2 class="no-result-header">No items found</h2>
        <p class="no-result-text">
          Back to
          <router-link to="/">main page</router-link>
        </p>
      </div>
      <div v-else class="results">
        <item-card v-for="item in items" :key="item._id" :data="item" />
      </div>
    </div>
    <universal-modal :modal="modal" @closeModal="closeModal" />
  </b-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import ItemCard from "@/components/common/ItemCard.vue";
import UniversalModal from "@/components/common/UniversalModal.vue";
import axios3C from "../axios";
import { checkUserCountry } from "ozparts-client";

export default {
  name: "TheSearch",
  components: { ItemCard, UniversalModal },
  beforeRouteUpdate(to, from, next) {
    this.search();
    next();
  },
  data() {
    return {
      items: [],
      showLoader: false,
      modal: {
        header: "Server error",
        text: "",
        show: false
      }
    };
  },
  computed: {
    ...mapState(["userCountry", "currency"])
  },
  async created() {
    if (!this.userCountry) {
      const res = await checkUserCountry();
      this.setUserCountry(res);
    }
    this.search();
  },
  methods: {
    ...mapMutations(["setUserCountry"]),
    closeModal() {
      this.modal.show = false;
    },
    error(err) {
      const error = (err.response && err.response.data) || err;
      this.modal.header = "Server error";
      this.modal.text = error.message || error.errmsg || error;
      this.modal.code = error.code;
      this.modal.show = true;
    },
    search() {
      this.showLoader = true;
      const keyword = this.$route.params.phrase.trim();
      const deep = this.$route.query.deep;
      let payload = {
        action: "search",
        keyword,
        customer: "guest",
        currency: this.currency,
        country: this.userCountry
      };
      if (deep) {
        payload = { ...payload, deep: true };
      }
      axios3C
        .post("/search", payload)
        .then(res => {
          this.items = res.data;
        })
        .catch(err => {
          this.error(err);
        })
        .finally(() => {
          this.showLoader = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.loader {
  margin: 1em auto;
  display: flex;
  justify-content: center;
}

.no-result {
  text-align: center;
  margin: 20px 0;

  &-header {
    font-family: "Michroma", sans-serif;
  }

  &-text {
    font-family: "Montserrat", sans-serif;
    margin-top: 10px;
  }
}

.results {
  display: flex;
  flex-wrap: wrap;
}
</style>
