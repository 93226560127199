<template>
  <router-link tag="div" :to="to" class="wrapper" active-class="mobile-active">
    <v-icon :color="$route.path === to ? 'red' : null" class="icon" size="40">{{
      icon
    }}</v-icon>
    <v-badge
      v-if="icon === 'mdi-cart'"
      color="primary"
      offset-x="-15"
      offset-y="-35"
    >
      <template v-slot:badge>{{ basketQuantity || 0 }}</template>
    </v-badge>
    <p>{{ text }}</p>
  </router-link>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "MobileIcon",
  props: {
    to: String,
    text: String,
    icon: String
  },
  computed: {
    ...mapState(["basketQuantity"])
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  border-radius: 15%;
  text-align: center;
  font-family: "Montserrat";
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  background-color: lighten($color: lightgray, $amount: 14);
  font-size: 12px;
  cursor: pointer;

  .icon {
    margin: 10px 0;
  }

  p {
    margin-bottom: 5px;
  }
}
</style>

<style lang="scss" scoped>
.wrapper + .mobile-active {
  color: var(--red);
}
</style>
