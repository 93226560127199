<template>
  <div>
    <p>We use the following company to deliver your goods:</p>
    <p class="bold">
      UPS Standard, UPS Express (next day delivery) - Orders placed online till
      1:00 pm are being processed the same day
    </p>
    <p>Given number of days does not include the day of the dispatch.</p>
    <figure class="figure">
      <img :src="ups" alt="UPS" />
      <figcaption>
        *Image source: www.ups.com. The transit times reflect UPS' regular
        delivery schedule, but are not guaranteed
      </figcaption>
    </figure>
    <p class="bold">UPS</p>
    <table class="table table-striped table-bordered">
      <tbody>
        <tr>
          <th>Country:</th>
          <th>UPS Estimate lead time (working days)</th>
        </tr>
        <tr>
          <td>Germany, Czech Republic, Slovakia</td>
          <td>2 days</td>
        </tr>
        <tr>
          <td>
            France, Belgium, Denmark, Netherlands, Austria, Switzerland
          </td>
          <td>3 days</td>
        </tr>
        <tr>
          <td>United Kingdom, Spain, Italy, Slovenia, Hungary, Romania</td>
          <td>4 days</td>
        </tr>
        <tr>
          <td>
            Portugal, Greece, Croatia, Lithuania, Latvia, Estonia, Norway
          </td>
          <td>5 days</td>
        </tr>
      </tbody>
    </table>
    <p class="bold">DPD</p>
    <table class="table table-striped table-bordered">
      <tbody>
        <tr>
          <th>Country:</th>
          <th>DPD Estimate lead time (working days)</th>
        </tr>
        <tr>
          <td>Poland</td>
          <td>1 day</td>
        </tr>
        <tr>
          <td>Belgium, Czech Republic, Germany, Netherlands</td>
          <td>3 days</td>
        </tr>
        <tr>
          <td>France, Romania, Sweden</td>
          <td>4 days</td>
        </tr>
        <tr>
          <td>Finland, Italy, Portugal, Spain, Bulgaria</td>
          <td>5 days</td>
        </tr>
        <tr>
          <td>Greece</td>
          <td>6 days</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ups from "@/assets/UPS.png";

export default {
  data() {
    return {
      ups
    };
  }
};
</script>
